import Axios from 'axios'
import React, { useState, useEffect, useRef, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom'
import styled from 'styled-components'
import Cookies from 'universal-cookie'

import AccountSetup from './UI/AccountSetup'
import AdminRoute from './routes/AdminRoute'
import { check } from './UI/CanUser'
import EmailVerification from './UI/EmailVerification'
import ForgotPassword from './UI/ForgotPassword'
import FullPageSpinner from './UI/FullPageSpinner'
import Login from './UI/Login'
import { useNotification } from './UI/NotificationProvider'
import PoweredByImage from './assets/powered-by.png'
import PasswordReset from './UI/PasswordReset'
import FormVerify from './UI/FormVerify'
import SessionProvider from './UI/SessionProvider'
import { handleImageSrc } from './UI/util'

import store from './store'
import {
  setCredentials,
  clearCredentialsState,
} from './redux/credentialsReducer'
import {
  setContacts,
  setContactSelected,
  setConnection,
  setPendingConnections,
  setWaitingForContacts,
  setWaitingForPendingConnections,
  clearContactsState,
  setPingData,
  setPongData,
} from './redux/contactsReducer'
import {
  setInvitations,
  setInvitationURL,
  setInvitationSelected,
  setWaitingForInvitations,
  clearInvitationsState,
} from './redux/invitationsReducer'
import {
  setLoggedIn,
  setLoggedInUserId,
  setLoggedInUsername,
  setLoggedInRoles,
  setLoggedInUserState,
  logoutUser,
} from './redux/loginReducer'
import {
  clearNotificationsState,
  setNotificationState,
} from './redux/notificationsReducer'
import {
  setLogo,
  setOrganizationName,
  setSchemas,
  setSMTP,
  setTheme,
  setSiteTitle,
  clearSettingsState,
  getLogo,
  getSiteTitle,
  getTheme,
} from './redux/settingsReducer'
import {
  setUsers,
  setUser,
  setRoles,
  clearUsersState,
} from './redux/usersReducer'
import {
  setWebsocket,
  setReadyForWebsocketMessages,
  clearWebsockets,
} from './redux/websocketsReducer'

import './App.css'
import { PoweredBox, PoweredBy } from './UI/CommonStylesForms'

const Frame = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
`
const Main = styled.main`
  flex: 9;
  padding: 30px;
`

function App(props) {
  const dispatch = useDispatch()
  const loginState = useSelector((state) => state.login)
  const notificationsState = useSelector((state) => state.notifications)
  const settingsState = useSelector((state) => state.settings)
  const websocketsState = useSelector((state) => state.websockets)
  const { notificationType, notificationMessage } = notificationsState
  const { websocket, readyForWebsocketMessages } = websocketsState

  const setNotification = useNotification()

  let currentState
  const updateState = () => {
    //Update to current redux state
    currentState = store.getState()
  }

  // Websocket reference hook
  const controllerSocket = useRef()

  // (AmmonBurgi) Keeps track of loading processes. The useMemo is necessary to preserve list across re-renders.
  const loadingList = useMemo(() => [], [])
  const [appIsLoaded, setAppIsLoaded] = useState(false)

  // Styles to change array
  const [stylesArray, setStylesArray] = useState([])

  const cookies = new Cookies()
  const [session, setSession] = useState('')
  const [sessionTimer] = useState(60)

  const [focusedConnectionID, setFocusedConnectionID] = useState('')

  useEffect(() => {
    if ((notificationMessage, notificationType)) {
      setNotification(notificationMessage, notificationType)
      dispatch(clearNotificationsState())
    }
  }, [notificationMessage, notificationType])

  // TODO: Setting logged-in user and session states on app mount
  useEffect(() => {
    Axios({
      method: 'GET',
      url: '/api/renew-session',
    })
      .then((res) => {
        if (cookies.get('sessionId')) {
          // Update session expiration date
          setSession(cookies.get('sessionId'))
          dispatch(setLoggedIn(true))

          dispatch(setLoggedInUserState(res.data))
          dispatch(setLoggedInUserId(res.data.id))
          dispatch(setLoggedInUsername(res.data.username))
          dispatch(setLoggedInRoles(res.data.roles))

          setAppIsLoaded(false)
        } else setAppIsLoaded(true)
      })
      .catch((error) => {
        // Unauthorized
        // requestLogo()
        dispatch(getLogo())
        dispatch(getSiteTitle())
        dispatch(getTheme())
        setAppIsLoaded(true)
      })
  }, [loginState.loggedIn])

  // (JamesKEbert) Note: We may want to abstract the websockets out into a high-order component for better abstraction, especially potentially with authentication/authorization

  // Perform First Time Setup. Connect to Controller Server via Websockets

  // Setting up websocket and controllerSocket
  useEffect(() => {
    if (session && loginState.loggedIn) {
      let url = new URL('/api/ws', window.location.href)
      url.protocol = url.protocol.replace('http', 'ws')
      controllerSocket.current = new WebSocket(url.href)

      controllerSocket.current.onopen = () => {
        dispatch(setWebsocket(true))
      }

      controllerSocket.current.onclose = (event) => {
        // Auto Reopen websocket connection
        // (JamesKEbert) TODO: Converse on sessions, session timeout and associated UI

        dispatch(setReadyForWebsocketMessages(false))
        dispatch(setWebsocket(false))
      }

      // Error Handler
      controllerSocket.current.onerror = (event) => {
        dispatch(
          setNotificationState({
            message: 'Client Error - Websockets',
            type: 'error',
          })
        )
      }

      // Receive new message from Controller Server
      controllerSocket.current.onmessage = (message) => {
        const parsedMessage = JSON.parse(message.data)

        messageHandler(
          parsedMessage.context,
          parsedMessage.type,
          parsedMessage.data
        )
      }
    }
  }, [loginState.loggedIn, session])

  // (eldersonar) Set-up site title. What about SEO? Will robots be able to read it?
  useEffect(() => {
    document.title = settingsState.siteTitle
  }, [settingsState.siteTitle])

  // Define Websocket event listeners
  useEffect(() => {
    // Perform operation on websocket open
    // Run web sockets only if authenticated
    if (
      session &&
      loginState.loggedIn &&
      websocket &&
      readyForWebsocketMessages &&
      loginState.loggedInUserState &&
      loadingList.length === 0
    ) {
      sendMessage('IMAGES', 'GET_ALL', {})
      addLoadingProcess('LOGO')
      sendMessage('SETTINGS', 'GET_THEME', {})
      addLoadingProcess('THEME')
      sendMessage('SETTINGS', 'GET_SCHEMAS', {})
      addLoadingProcess('SCHEMAS')

      if (check('credentials:read')) {
        sendMessage('CREDENTIALS', 'GET_ALL', {})
        addLoadingProcess('CREDENTIALS')
      }

      if (check('roles:read')) {
        sendMessage('ROLES', 'GET_ALL', {})
        addLoadingProcess('ROLES')
      }

      sendMessage('SETTINGS', 'GET_ORGANIZATION', {})
      addLoadingProcess('ORGANIZATION')

      if (check('settings:update')) {
        sendMessage('SETTINGS', 'GET_SMTP', {})
        addLoadingProcess('SMTP')
      }

      if (check('users:read')) {
        sendMessage('USERS', 'GET_ALL', {})
        addLoadingProcess('USERS')
      }
    }
  }, [
    session,
    loginState.loggedIn,
    websocket,
    readyForWebsocketMessages,
    loginState.loggedInUserState,
  ])

  // (eldersonar) Shut down the websocket
  function closeWSConnection(code, reason) {
    controllerSocket.current.close(code, reason)
  }

  // Send a message to the Controller server
  function sendMessage(context, type, data = {}) {
    if (websocket) {
      controllerSocket.current.send(JSON.stringify({ context, type, data }))
    }
  }

  // Handle inbound messages
  const messageHandler = async (context, type, data = {}) => {
    updateState()

    try {
      console.log(
        `New Message with context: '${context}' and type: '${type}' with data:`,
        data
      )
      switch (context) {
        case 'INVITATIONS':
          switch (type) {
            case 'INVITATION':
              dispatch(setInvitationURL(data.invitation_url))
              dispatch(setInvitationSelected(data))

              break

            case 'INVITATIONS':
              dispatch(setInvitations(data))
              dispatch(setWaitingForInvitations(false))

              break

            case 'INVITATION_UPDATED':
              console.log('Invitation has been updated!')

              const activeInvitation =
                currentState.invitations.invitationSelected
              if (
                activeInvitation.invitation_id ===
                data.updatedInvitation.invitation_id
              ) {
                dispatch(setInvitationSelected(data.updatedInvitation))
              }

              break

            default:
              dispatch(
                setNotificationState({
                  message: `Error - Unrecognized Websocket Message Type: ${type}`,
                  type: 'error',
                })
              )
              break
          }
          break

        case 'CONTACTS':
          switch (type) {
            case 'CONTACTS':
              dispatch(setContacts(data.contacts))
              dispatch(setWaitingForContacts(false))

              break

            case 'CONTACT':
              dispatch(setContactSelected(data.contact))

              break

            default:
              dispatch(
                setNotificationState({
                  message: `Error - Unrecognized Websocket Message Type: ${type}`,
                  type: 'error',
                })
              )
              break
          }
          break

        case 'CONTACT':
          switch (type) {
            case 'PING':
              dispatch(setPingData(data))
              console.log('victory')

              break
            case 'PING_RESPONSE_RECEIVED':
              if (
                data.threadId ==
                currentState.contacts.pingData.pingData.threadId
              ) {
                const pongData = {
                  connectionId:
                    currentState.contacts.pingData.pingData.connectionId,
                }
                dispatch(setPongData(pongData))
              }
              break

            default:
              dispatch(
                setNotificationState({
                  message: `Error - Unrecognized Websocket Message Type: ${type}`,
                  type: 'error',
                })
              )

              break
          }
          break

        case 'CONNECTIONS':
          switch (type) {
            case 'PENDING_CONNECTIONS':
              dispatch(setPendingConnections(data.pendingConnections))
              dispatch(setWaitingForPendingConnections(false))

              break

            case 'CONNECTION':
              //(AmmonBurgi) Receives the most recent connection with state "active" or "completed"
              dispatch(setConnection(data.connection))

              break

            default:
              setNotification(
                `Error - Unrecognized Websocket Message Type: ${type}`,
                'error'
              )
              break
          }
          break

        case 'OUT_OF_BAND':
          switch (type) {
            case 'INVITATION':
              dispatch(setInvitationURL(data.invitation_url))
              dispatch(setInvitationSelected(data))

              break

            case 'CONNECTION_REUSE':
              console.log(data.comment)

              const activeInvitation =
                currentState.invitations.invitationSelected
              if (
                activeInvitation.invitation_msg_id === data.invitation_msg_id
              ) {
                const message = `Connection reused for ${data.connection_id}`

                dispatch(
                  setNotificationState({ message: message, type: 'notice' })
                )
              }

              break

            default:
              setNotification(
                `Error - Unrecognized Websocket Message Type: ${type}`,
                'error'
              )
              break
          }
          break

        case 'ROLES':
          switch (type) {
            case 'ROLES':
              let oldRoles = currentState.users.roles
              let newRoles = data.roles
              let updatedRoles = []
              // (mikekebert) Loop through the new roles and check them against the existing array
              newRoles.forEach((newRole) => {
                oldRoles.forEach((oldRole, index) => {
                  if (
                    oldRole !== null &&
                    newRole !== null &&
                    oldRole.role_id === newRole.role_id
                  ) {
                    // (mikekebert) If you find a match, delete the old copy from the old array
                    oldRoles.splice(index, 1)
                  }
                })
                updatedRoles.push(newRole)
              })
              // (mikekebert) When you reach the end of the list of new roles, simply add any remaining old roles to the new array
              if (oldRoles.length > 0)
                updatedRoles = [...updatedRoles, ...oldRoles]

              dispatch(setRoles(updatedRoles))
              removeLoadingProcess('ROLES')
              break

            default:
              dispatch(
                setNotificationState({
                  message: `Error - Unrecognized Websocket Message Type: ${type}`,
                  type: 'error',
                })
              )

              break
          }
          break

        case 'USERS':
          switch (type) {
            case 'USERS':
              let oldUsers = currentState.users.users
              let newUsers = data.users
              let updatedUsers = []
              // (mikekebert) Loop through the new users and check them against the existing array
              newUsers.forEach((newUser) => {
                oldUsers.forEach((oldUser, index) => {
                  if (
                    oldUser !== null &&
                    newUser !== null &&
                    oldUser.user_id === newUser.user_id
                  ) {
                    // (mikekebert) If you find a match, delete the old copy from the old array
                    oldUsers.splice(index, 1)
                  }
                })
                updatedUsers.push(newUser)
              })
              // (mikekebert) When you reach the end of the list of new users, simply add any remaining old users to the new array
              if (oldUsers.length > 0)
                updatedUsers = [...updatedUsers, ...oldUsers]
              // (mikekebert) Sort the array by data created, newest on top
              updatedUsers.sort((a, b) =>
                a.created_at < b.created_at ? 1 : -1
              )

              dispatch(setUsers(updatedUsers))
              removeLoadingProcess('USERS')

              break

            case 'USER':
              let user = data.user[0]
              dispatch(setUser(user))
              break

            case 'USER_UPDATED':
              dispatch(
                setUsers(
                  currentState.users.users.map((x) =>
                    x.user_id === data.updatedUser.user_id
                      ? data.updatedUser
                      : x
                  )
                )
              )
              dispatch(setUser(data.updatedUser))
              break

            case 'PASSWORD_UPDATED':
              dispatch(
                setUsers(
                  currentState.users.users.map((x) =>
                    x.user_id === data.updatedUserPassword.user_id
                      ? data.updatedUserPassword
                      : x
                  )
                )
              )
              break

            case 'USER_CREATED':
              let usersCreated = [...currentState.users.users, data.user[0]]
              usersCreated.sort((a, b) =>
                a.created_at < b.created_at ? 1 : -1
              )
              dispatch(setUsers(usersCreated))
              dispatch(setUser(data.user[0]))
              break

            case 'USER_DELETED':
              const currentUsers = currentState.users.users
              const index = currentUsers.findIndex((v) => v.user_id === data)
              let alteredUsers = [...currentUsers]
              alteredUsers.splice(index, 1)
              dispatch(setUsers(alteredUsers))

              break

            default:
              dispatch(
                setNotificationState({
                  message: `Error - Unrecognized Websocket Message Type: ${type}`,
                  type: 'error',
                })
              )

              break
          }
          break

        case 'CREDENTIALS':
          switch (type) {
            case 'CREDENTIALS':
              let oldCredentials = currentState.credentials.credentials
              let newCredentials = data.credential_records
              let updatedCredentials = []
              // (mikekebert) Loop through the new credentials and check them against the existing array
              newCredentials.forEach((newCredential) => {
                oldCredentials.forEach((oldCredential, index) => {
                  if (
                    oldCredential !== null &&
                    newCredential !== null &&
                    oldCredential.credential_exchange_id ===
                      newCredential.credential_exchange_id
                  ) {
                    // (mikekebert) If you find a match, delete the old copy from the old array
                    oldCredentials.splice(index, 1)
                  }
                })
                updatedCredentials.push(newCredential)
                // (mikekebert) We also want to make sure to reset any pending connection IDs so the modal windows don't pop up automatically
                if (newCredential.connection_id === focusedConnectionID) {
                  setFocusedConnectionID('')
                }
              })
              // (mikekebert) When you reach the end of the list of new credentials, simply add any remaining old credentials to the new array
              if (oldCredentials.length > 0)
                updatedCredentials = [...updatedCredentials, ...oldCredentials]
              // (mikekebert) Sort the array by data created, newest on top
              updatedCredentials.sort((a, b) =>
                a.created_at < b.created_at ? 1 : -1
              )

              dispatch(setCredentials(updatedCredentials))
              removeLoadingProcess('CREDENTIALS')
              break

            default:
              dispatch(
                setNotificationState({
                  message: `Error - Unrecognized Websocket Message Type: ${type}`,
                  type: 'error',
                })
              )

              break
          }

          break
        case 'PRESENTATIONS':
          switch (type) {
            case 'VERIFIED':
              dispatch(
                setNotificationState({
                  message: 'Success - Verified Credential',
                  type: 'notice',
                })
              )

              break

            default:
              dispatch(
                setNotificationState({
                  message: `Error - Unrecognized Websocket Message Type: ${type}`,
                  type: 'error',
                })
              )

              break
          }
          break

        case 'SERVER':
          switch (type) {
            case 'NOTIFICATIONS':
              dispatch(setNotificationState(data))

              break

            case 'SERVER_ERROR':
              console.log(data)
              dispatch(
                setNotificationState({
                  message: `Server Error - ${data.errorCode} \n Reason: '${data.errorReason}'`,
                  type: 'error',
                })
              )

              break

            case 'WEBSOCKET_READY':
              dispatch(setReadyForWebsocketMessages(true))

              break

            default:
              dispatch(
                setNotificationState({
                  message: `Error - Unrecognized Websocket Message Type: ${type}`,
                  type: 'error',
                })
              )

              break
          }
          break

        case 'SETTINGS':
          switch (type) {
            case 'SETTINGS_THEME':
              // Writing the recent theme to a local storage
              const stringMessageTheme = JSON.stringify(data.value)
              window.localStorage.setItem('recentTheme', stringMessageTheme)
              dispatch(setTheme(data.value))
              removeLoadingProcess('THEME')
              break

            case 'SETTINGS_SCHEMAS':
              dispatch(setSchemas(data))
              removeLoadingProcess('SCHEMAS')
              break

            case 'LOGO':
              dispatch(setLogo(handleImageSrc(data.image.data)))
              removeLoadingProcess('LOGO')
              break

            case 'SETTINGS_ORGANIZATION':
              dispatch(setOrganizationName(data.organizationName))
              dispatch(setSiteTitle(data.title))
              removeLoadingProcess('ORGANIZATION')
              break

            case 'SETTINGS_SMTP':
              dispatch(setSMTP(data.value))
              removeLoadingProcess('SMTP')
              break

            default:
              dispatch(
                setNotificationState({
                  message: `Error - Unrecognized Websocket Message Type: ${type}`,
                  type: 'error',
                })
              )

              break
          }
          break

        case 'IMAGES':
          switch (type) {
            case 'IMAGE_LIST':
              dispatch(setLogo(handleImageSrc(data.image.data)))

              removeLoadingProcess('IMAGES')
              break

            default:
              dispatch(
                setNotificationState({
                  message: `Error - Unrecognized Websocket Message Type: ${type}`,
                  type: 'error',
                })
              )

              break
          }
          break

        case 'ORGANIZATION':
          switch (type) {
            case 'ORGANIZATION_NAME':
              dispatch(setOrganizationName(data[0].value.name))

              removeLoadingProcess('ORGANIZATION')
              break

            default:
              dispatch(
                setNotificationState({
                  message: `Error - Unrecognized Websocket Message Type: ${type}`,
                  type: 'error',
                })
              )

              break
          }
          break

        default:
          dispatch(
            setNotificationState({
              message: `Error - Unrecognized Websocket Message Type: ${context}`,
              type: 'error',
            })
          )

          break
      }
    } catch (error) {
      console.log('Error caught:', error)
      dispatch(
        setNotificationState({
          message: 'Client Error - Websocket',
          type: 'error',
        })
      )
    }
  }

  function addLoadingProcess(process) {
    loadingList.push(process)
  }

  function clearLoadingProcess() {
    loadingList.length = 0
    setAppIsLoaded(true)
  }

  function removeLoadingProcess(process) {
    const index = loadingList.indexOf(process)
    if (index > -1) {
      loadingList.splice(index, 1)
    }

    if (loadingList.length === 0) {
      setAppIsLoaded(true)
    }
  }

  function setUpUser(id, username, roles) {
    setSession(cookies.get('sessionId'))
    dispatch(setLoggedInUserId(id))
    dispatch(setLoggedInUsername(username))
    dispatch(setLoggedInRoles(roles))
  }

  // Update theme state locally
  const updateTheme = (update) => {
    updateState()
    return dispatch(setTheme({ ...currentState.settings.theme, ...update }))
  }

  // Update theme in the database
  const saveTheme = () => {
    sendMessage('SETTINGS', 'SET_THEME', settingsState.theme)
  }

  //(RomanStepanyan) Removing all styles from an array of styles to desible undo button
  const clearStylesArray = () => {
    setStylesArray([])
  }

  const addStylesToArray = (key) => {
    let position = stylesArray.indexOf(key)
    // if cannot find indexOf style
    if (!~position) {
      setStylesArray((oldArray) => [...oldArray, `${key}`])
    }
  }

  const removeStylesFromArray = (undoKey) => {
    // Removing a style from an array of styles
    let index = stylesArray.indexOf(undoKey)
    if (index > -1) {
      stylesArray.splice(index, 1)
      setStylesArray(stylesArray)
    }
  }

  // Undo theme change
  const undoStyle = (undoKey) => {
    const recentTheme = JSON.parse(localStorage.getItem('recentTheme'))
    updateState()

    if (undoKey !== undefined) {
      for (let key in recentTheme)
        if ((key = undoKey)) {
          const undo = { [`${key}`]: recentTheme[key] }
          return dispatch(setTheme({ ...currentState.settings.theme, ...undo }))
        }
    }
  }

  // Logout and redirect
  const handleLogout = (history) => {
    Axios({
      method: 'POST',
      url: '/api/user/log-out',
      withCredentals: true,
    }).then((res) => {
      setSession('')

      dispatch(clearWebsockets())
      dispatch(clearCredentialsState())
      dispatch(clearContactsState())
      dispatch(clearInvitationsState())
      dispatch(clearSettingsState())
      dispatch(clearUsersState())
      dispatch(logoutUser())
      // (eldersonar) Does this close the connection and remove the connection object?
      closeWSConnection(1000, 'Log out')
      if (history !== undefined) {
        history.push('/admin/login')
      }
    })
  }

  if (
    (loginState.loggedIn && !appIsLoaded) ||
    (!loginState.loggedIn && !appIsLoaded)
  ) {
    // Show the spinner while the app is loading
    return <FullPageSpinner />
  } else if (!loginState.loggedIn && appIsLoaded) {
    return (
      <Router>
        <Switch>
          <Route
            path="/forgot-password"
            render={({ match, history }) => {
              return (
                <>
                  <Frame id="app-frame">
                    <Main>
                      <ForgotPassword
                        history={history}
                        sendRequest={sendMessage}
                      />
                    </Main>
                  </Frame>
                  <PoweredBox>
                    <PoweredBy src={PoweredByImage} alt="Powered By Indicio" />
                  </PoweredBox>
                </>
              )
            }}
          />
          <Route
            path="/password-reset"
            render={({ match, history }) => {
              return (
                <>
                  <Frame id="app-frame">
                    <Main>
                      <PasswordReset
                        history={history}
                        sendRequest={sendMessage}
                      />
                    </Main>
                  </Frame>
                  <PoweredBox>
                    <PoweredBy src={PoweredByImage} alt="Powered By Indicio" />
                  </PoweredBox>
                </>
              )
            }}
          />
          <Route
            path="/account-setup"
            render={({ match, history }) => {
              return (
                <>
                  <Frame id="app-frame">
                    <Main>
                      <AccountSetup
                        history={history}
                        sendRequest={sendMessage}
                        messageHandler={messageHandler}
                      />
                    </Main>
                  </Frame>
                  <PoweredBox>
                    <PoweredBy src={PoweredByImage} alt="Powered By Indicio" />
                  </PoweredBox>
                </>
              )
            }}
          />
          <Route
            path="/emailVerification"
            render={({ match, history }) => {
              return (
                <>
                  <Frame id="app-frame">
                    <Main>
                      <EmailVerification
                        history={history}
                        sendRequest={sendMessage}
                        messageHandler={messageHandler}
                      />
                    </Main>
                  </Frame>
                  <PoweredBox>
                    <PoweredBy src={PoweredByImage} alt="Powered By Indicio" />
                  </PoweredBox>
                </>
              )
            }}
          />
          <Route
            path="/admin/login"
            render={({ match, history }) => {
              return (
                <>
                  <Frame id="app-frame">
                    <Main>
                      <Login
                        history={history}
                        setUpUser={setUpUser}
                        sendRequest={sendMessage}
                      />
                    </Main>
                  </Frame>
                  <PoweredBox>
                    <PoweredBy src={PoweredByImage} alt="Powered By Indicio" />
                  </PoweredBox>
                </>
              )
            }}
          />
          <Route
            path="/"
            exact
            render={() => {
              return <FormVerify sendRequest={sendMessage} />
            }}
          />
          <Route path="/admin">
            <Redirect to="/admin/login" />
          </Route>
          <Route render={() => <Redirect to="/" />} />
        </Switch>
      </Router>
    )
  } else {
    // loggedIn and appIsLoaded
    return (
      <SessionProvider logout={handleLogout} sessionTimer={sessionTimer}>
        <Router>
          <Switch>
            <Route
              path="/admin"
              render={() => {
                return (
                  <AdminRoute
                    handleLogout={handleLogout}
                    sendMessage={sendMessage}
                    updateTheme={updateTheme}
                    saveTheme={saveTheme}
                    undoStyle={undoStyle}
                    stylesArray={stylesArray}
                    clearStylesArray={clearStylesArray}
                    addStylesToArray={addStylesToArray}
                    removeStylesFromArray={removeStylesFromArray}
                  />
                )
              }}
            />
            {/* Redirect to root if no route match is found */}
            <Route render={() => <Redirect to="/admin" />} />
          </Switch>
        </Router>
      </SessionProvider>
    )
  }
}

export default App
