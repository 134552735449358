import React, { useRef, useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Axios from 'axios'
import styled from 'styled-components'

import {
  setInvitationSelected,
  setInvitationURL,
} from '../redux/invitationsReducer'
import { setNotificationState } from '../redux/notificationsReducer'

// import FormInvitationEmail from './FormInvitationEmail'
// import FormEmploymentIssuance from './FormEmploymentIssuance'
import FormDtcIssuance from './FormDtcIssuance'
import PageHeader from './PageHeader.js'
import PageSection from './PageSection.js'

import { SubmitBtnModal, Modal, Actions, Select } from './CommonStylesForms'
const HomeHeader = styled.h2`
  display: inline-block;
  margin-right: 10px;
  font-size: 1.3em;
`
const Form = styled.form`
  overflow: hidden;
  margin-bottom: 10px;
`

function Home(props) {
  const dispatch = useDispatch()
  const settingsState = useSelector((state) => state.settings)
  const organizationName = settingsState.organizationName
  const submitBtn = useRef()

  // const [emailModalIsOpen, setEmailModalIsOpen] = useState(false)
  // const closeEmailModal = () => setEmailModalIsOpen(false)

  // const [employmentModalIsOpen, setEmploymentModalIsOpen] = useState(false)
  // const closeEmploymentModal = () => setEmploymentModalIsOpen(false)

  const [dtcModalIsOpen, setDtcModalIsOpen] = useState(false)
  const closeDtcModal = () => setDtcModalIsOpen(false)

  const [chosenCredential, setChosenCredential] = useState('')

  const [csvData, setCsvData] = useState([])

  useEffect(() => {
    if (csvData.length > 0) {
      submitBtn.current.removeAttribute('disabled')
    }
  }, [csvData])

  const onBtnClick = (e) => {
    if (submitBtn.current) {
      submitBtn.current.setAttribute('disabled', 'disabled')
    }
  }

  function handleCredChange(e) {
    setChosenCredential(e.target.value)
  }

  function handleOpen(e) {
    e.preventDefault()

    // if (chosenCredential === 'email') {
    //   setEmailModalIsOpen((o) => !o)
    // } else if (chosenCredential === 'employment') {
    //   //(AmmonBurgi) We need to reset the invitation so the modal doesn't proceed before a new invitation is received.
    //   dispatch(setInvitationSelected({}))
    //   dispatch(setInvitationURL(''))

    //   setEmploymentModalIsOpen((o) => !o)
    //   props.sendRequest('INVITATIONS', 'CREATE', {
    //     alias: 'Invitation',
    //     invitationMode: 'once',
    //     accept: 'auto',
    //     public: false,
    //     invitationStatus: 'active',
    //     invitationDescription: 'Invitation for Employment credential issuance.',
    //   })
    if (chosenCredential === 'dtc') {
      //(AmmonBurgi) We need to reset the invitation so the modal doesn't proceed before a new invitation is received.
      dispatch(setInvitationSelected({}))
      dispatch(setInvitationURL(''))

      setDtcModalIsOpen((o) => !o)
      props.sendRequest('INVITATIONS', 'CREATE', {
        alias: 'Invitation',
        invitationMode: 'once',
        accept: 'auto',
        public: false,
        invitationStatus: 'active',
        invitationDescription: 'Invitation for DTC credential issuance.',
      })

    } else {
      return
    }
  }
  //(RomanStepanyan) Converting CSV file to an array of objects
  function csvToArray(str, delimiter = ',') {
    const rows = str.split(/(?:\r\n|\n)+/).filter((el) => {
      return el.length !== 0
    })
    const headers = rows.splice(0, 1)[0].split(',')

    const arr = rows.map(function (row) {
      //Splitting and removing white spaces
      const values = row.replace(/\s/g, '').split(delimiter)
      //Assemble the object
      const el = headers.reduce((object, header, index) => {
        object[header] = values[index]
        return object
      }, {})
      return el
    })
    setCsvData(arr)
  }

  //(RomanStepanyan) CSV file upload
  let csvFileSelectHandler = (event) => {
    event.preventDefault()
    const file = event.target.files[0]
    console.log('file:', file)

    if (file) {
      const reader = new FileReader()
      reader.onload = function (e) {
        csvToArray(reader.result)
      }
      reader.readAsText(file)
    }
    dispatch(
      setNotificationState({
        message: `Your CSV file, ${file.name}, has been imported. Next, click on "Send Emails".`,
        type: 'notice',
      })
    )
  }

  const handleCsvSubmit = async (e) => {
    e.preventDefault()
    onBtnClick()
    if (csvData) {
      //(RomanStepanyan) Sending an array with converted CSV data
      Axios({
        method: 'POST',
        data: {
          emails: csvData,
        },
        url: '/api/emails/verify',
      }).then((res) => {
        if (res.data.error) {
          dispatch(
            setNotificationState({ message: res.data.error, type: 'error' })
          )
        } else {
          dispatch(
            setNotificationState({
              message: 'Email invitations have been sent!',
              type: 'notice',
            })
          )
        }
      })
    } else {
      dispatch(
        setNotificationState({
          message: 'The CSV file is not selected.',
          type: 'error',
        })
      )
    }
  }

  return (
    <>
      {/* welcome to the ___ enterprice issuer */}
      <PageHeader title={organizationName} />
      <PageSection>
        <form>
          <HomeHeader>Workflow</HomeHeader>
          <p>Select a Credential to Issue:</p>
          <Modal>
            <Actions>
              <Select
                id="chosenCred"
                name="chosenCred"
                value={chosenCredential}
                onChange={(e) => handleCredChange(e)}
              >
                <option> ---Choose Credential--- </option>
                {/* <option value="email"> Email Credential</option>
                <option value="employment"> Employment Credential</option> */}
                {/* <option value="sms" disabled> SMS Credential</option>   */}
                <option value="dtc"> DTC Credential</option>

              </Select>
              <SubmitBtnModal
                type="submit"
                onClick={(e) => {
                  handleOpen(e)
                }}
              >
                Issue
              </SubmitBtnModal>
            </Actions>
          </Modal>
        </form>
      </PageSection>
      <PageSection>
        <HomeHeader>Bulk Import Mails</HomeHeader>
        <p>
          Proven provides several ways to issue email credentials. Users can
          request an email invitation using the self-serve UI, administrators
          send invitations one-by-one or by uploading a CSV file in the admin
          UI, and developers request invitations via the API.
        </p>
        <Form onSubmit={handleCsvSubmit}>
          <input type="file" accept=".csv" onChange={csvFileSelectHandler} />
          <button type="submit" ref={submitBtn} disabled>
            Send Emails
          </button>
        </Form>
      </PageSection>
      {/* <FormInvitationEmail
        emailModalIsOpen={emailModalIsOpen}
        closeEmailModal={closeEmailModal}
      />
      <FormEmploymentIssuance
        employmentModalIsOpen={employmentModalIsOpen}
        closeEmploymentModal={closeEmploymentModal}
        sendRequest={props.sendRequest}
      /> */}
      <FormDtcIssuance
        dtcModalIsOpen={dtcModalIsOpen}
        closeDtcModal={closeDtcModal}
        sendRequest={props.sendRequest}
      />
    </>
  )
}

export default Home
