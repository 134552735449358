import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { check } from './CanUser'

import PageHeader from './PageHeader.js'
import PageSection from './PageSection.js'
import styled from 'styled-components'
import { setContactSelected } from '../redux/contactsReducer.js'
import { setNotificationState } from '../redux/notificationsReducer'

import {
  DataTable,
  DataRow,
  DataHeader,
  DataCell,
  AttributeTable,
  AttributeRow,
  IconCheck,
} from './CommonStylesTables'
import { LoadingHolder, Spinner } from './LoadingStyles'

const Chip = styled.div`
  float: right;
  padding: 8px 10px;
  margin-right: 10px;
  line-height: 20px;
`
const PingBtn = styled.button`
  float: right;
  padding: 10px 12px;
  margin-right: 10px;
  line-height: 20px;
  color: ${(props) => props.theme.text_light};
  border: none;
  box-shadow: ${(props) => props.theme.drop_shadow};
  background: ${(props) => props.theme.primary_color};
`
const InteractionButton = styled.button`
  float: right;
  padding: 10px 12px;
  margin-right: 10px;
  line-height: 20px;
  color: ${(props) => props.theme.text_light};
  border: none;
  box-shadow: ${(props) => props.theme.drop_shadow};
  background: ${(props) => props.theme.primary_color};
`

function Contact(props) {
  const dispatch = useDispatch()
  const contactsState = useSelector((state) => state.contacts)
  const credentials = useSelector((state) => state.credentials.credentials)
  const websocketsState = useSelector((state) => state.websockets)
  const contactSelected = contactsState.contactSelected
  const { websocket, readyForWebsocketMessages } = websocketsState

  const [connections, setConnections] = useState([])
  const [waitingForContact, setWaitingForContact] = useState(true)
  // const [threadId, setThreadId] = useState('')

  const { history, contactId, sendRequest } = props

  useEffect(() => {
    //(AmmonBurgi) Stop waiting for contactSelected if the contact_id matches the target ID. If no match is found, fetch the needed contact.
    if (websocket && readyForWebsocketMessages) {
      if (
        Object.keys(contactSelected).length !== 0 &&
        contactSelected.contact_id === contactId
      ) {
        setWaitingForContact(false)
        setConnections(contactSelected.Connections)
      } else {
        setWaitingForContact(true)
        if (check('contacts:read')) {
          sendRequest('CONTACTS', 'GET', {
            contact_id: contactId,
            additional_tables: [],
          })
        }
      }
    }
  }, [contactSelected, contactId, websocket, readyForWebsocketMessages])

  const connectionRows = connections.map((connection) => {
    return (
      <DataRow key={connection.connection_id}>
        <DataCell>{connection.connection_id}</DataCell>
        <DataCell className="title-case">{connection.state}</DataCell>
        <DataCell>{new Date(connection.created_at).toLocaleString()}</DataCell>
      </DataRow>
    )
  })

  function trustPing() {
    const connectionId = contactSelected.Connections[0].connection_id
    props.sendRequest('CONTACT', 'PING', { connectionId: connectionId })
  }

  function openCredential(history, id) {
    if (history !== undefined) {
      history.push('/admin/credentials/' + id)
    }
  }

  function Pong() {
    if (
      contactSelected.Connections &&
      contactSelected.Connections[0].connection_id ==
        contactsState?.pongData?.connectionId
    ) {
      return (
        <Chip>
          <IconCheck alt="Pong" />
        </Chip>
      )
    } else {
      return <Chip>&nbsp;</Chip>
    }
  }

  const credentialRows = credentials.map((credential_record) => {
    if (
      contactSelected.Connections &&
      contactSelected.Connections[0].connection_id ===
        credential_record.connection_id
    ) {
      const credential_id = credential_record.credential_exchange_id
      const credentialState = credential_record.state.replaceAll('_', ' ') || ''
      const dateCreated =
        new Date(credential_record.created_at).toLocaleString() || ''

      let credentialName = ''
      if (
        credential_record.credential_proposal_dict !== null &&
        credential_record.credential_proposal_dict !== undefined
      ) {
        credentialName = credential_record.credential_proposal_dict.schema_name.replaceAll(
          '_',
          ' '
        )
      }
      return (
        <DataRow
          key={credential_id}
          onClick={() => {
            openCredential(history, credential_id)
          }}
        >
          <DataCell>{credentialName}</DataCell>
          <DataCell className="title-case">{credentialState}</DataCell>
          <DataCell>{dateCreated}</DataCell>
        </DataRow>
      )
    } else {
      return []
    }
  })

  const onDiscoverFeatures = () => {
    props.sendRequest('DISCOVER_FEATURES', 'GET', {
      connection_id: contactSelected.Connections[0].connection_id,
    })

    dispatch(
      setNotificationState({
        message: 'See the backend logs for the result.',
        type: 'notice',
      })
    )
  }

  return (
    <>
      {!waitingForContact ? (
        <div id="contact">
          <PageHeader
            title={'Contact Details: ' + (contactSelected.label || '')}
          />
          <PageSection>
            <h2>General Information</h2>
            <AttributeTable>
              <tbody>
                <AttributeRow>
                  <th>Contact ID:</th>
                  <td>{contactSelected.contact_id || ''}</td>
                </AttributeRow>
                <AttributeRow>
                  <th>Connection Status:</th>
                  <td>
                    {contactSelected.Connections !== undefined
                      ? contactSelected.Connections[0].state || ''
                      : ''}
                  </td>
                </AttributeRow>
              </tbody>
            </AttributeTable>
            <h2>Contact Information</h2>
            <AttributeTable>
              <tbody>
                <AttributeRow>
                  <th>Name:</th>
                  <td>{contactSelected.label || ''}</td>
                </AttributeRow>
              </tbody>
            </AttributeTable>
            <InteractionButton onClick={() => onDiscoverFeatures()}>
              Discover Features v2.0
            </InteractionButton>
            <PingBtn
              onClick={() => {
                trustPing()
              }}
            >
              Ping
            </PingBtn>
            <Pong />
          </PageSection>
          <PageSection>
            <DataTable>
              <thead>
                <DataRow>
                  <DataHeader>Credential</DataHeader>
                  <DataHeader>Status</DataHeader>
                  <DataHeader>Date Issued</DataHeader>
                </DataRow>
              </thead>
              <tbody>{credentialRows}</tbody>
            </DataTable>
          </PageSection>
          <PageSection>
            <DataTable>
              <thead>
                <DataRow>
                  <DataHeader>Connection</DataHeader>
                  <DataHeader>Status</DataHeader>
                  <DataHeader>Created At</DataHeader>
                </DataRow>
              </thead>
              <tbody>{connectionRows}</tbody>
            </DataTable>
          </PageSection>
        </div>
      ) : (
        <LoadingHolder>
          <p>Fetching contacts, please wait...</p>
          <Spinner />
        </LoadingHolder>
      )}
    </>
  )
}

export default Contact
