import { DateTime } from 'luxon'
import React, { useEffect, useState, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import QRCode from 'qrcode.react'
import styled from 'styled-components'

import {
  setInvitationSelected,
  setInvitationURL,
} from '../redux/invitationsReducer'

import {
  StyledPopup,
  InputBox,
  ModalMedium,
  ModalHeader,
  ModalContentWrapper,
  ModalContent,
  CloseBtn,
  Actions,
  CancelBtn,
  SubmitBtnModal,
  ModalLabel,
  InputFieldModal,
} from './CommonStylesForms'
import { LoadingHolder, Spinner } from './LoadingStyles'
const QR = styled(QRCode)`
  display: block;
  margin: auto;
  padding: 20px;
  width: 300px;
  height: 300px;
  background-color: white;
`

function FormDtcIssuance(props) {
  const dispatch = useDispatch()
  const contactsState = useSelector((state) => state.contacts)
  const invitationsState = useSelector((state) => state.invitations)
  const schemas = useSelector((state) => state.settings.schemas)
  const { connection } = contactsState
  const { invitationSelected, invitationURL } = invitationsState

  const dtcForm = useRef(null)
  const [connectionFound, setConnectionFound] = useState(false)

  const { sendRequest, closeDtcModal, dtcModalIsOpen } = props

  useEffect(() => {
    //(AmmonBurgi) Find the active connection using the selected invitations connection_id
    if ((connection, invitationSelected)) {
      if (
        connection.connection_id &&
        invitationSelected.connection_id &&
        connection.connection_id === invitationSelected.connection_id
      ) {
        setConnectionFound(true)
      }
    }
  }, [connection, invitationSelected])

  const handleClosingModal = () => {
    setConnectionFound(false)
    dispatch(setInvitationURL(''))
    dispatch(setInvitationSelected({}))

    closeDtcModal()
  }

  const handleSubmit = (e) => {
    e.preventDefault()

    const form = new FormData(dtcForm.current)

    const dtcAttr = [
      {
        name: 'expiry-date',
        value: Math.floor(
            DateTime.fromISO(form.get('expiry-date')).ts / 1000
          ).toString(),
      },
      {
        name: 'dtc',
        value: form.get('dtc'),
      },
      {
        name: 'family-name',
        value: form.get('family-name'),
      },
      {
        name: 'issuing-state',
        value: form.get('issuing-state'),
      },
      {
        name: 'upk',
        value: form.get('upk'),
      },
      {
        name: 'chip-photo',
        value: form.get('chip-photo'),
      },
      {
        name: 'created-date',
        value: Math.floor(
            DateTime.fromISO(form.get('created-date')).ts / 1000
          ).toString(),
      },
      {
        name: 'date-of-birth',
        value: Math.floor(
            DateTime.fromISO(form.get('date-of-birth')).ts / 1000
          ).toString(),
      },
      {
        name: 'issuing-authority',
        value: form.get('issuing-authority'),
      },
      {
        name: 'gender',
        value: form.get('gender'),
      },
      {
        name: 'document-number',
        value: form.get('document-number'),
      },
      {
        name: 'issue-date',
        value: Math.floor(
            DateTime.fromISO(form.get('issue-date')).ts / 1000
          ).toString(),
      },
      {
        name: 'nationality',
        value: form.get('nationality'),
      },
      {
        name: 'document-type',
        value: form.get('document-type'),
      },
      {
        name: 'given-names',
        value: form.get('given-names'),
      },
    ]

    const schema = schemas.SCHEMA_DTC_TYPE1_IDENTITY
    const schemaParts = schema.split(':')

    sendRequest('CREDENTIALS', 'ISSUE_USING_SCHEMA', {
      connectionID: connection.connection_id,
      schemaID: schema,
      schemaVersion: schemaParts[3],
      schemaName: schemaParts[2],
      schemaIssuerDID: schemaParts[0],
      attributes: dtcAttr,
    })

    handleClosingModal()
  }

  return (
    <StyledPopup
      open={dtcModalIsOpen}
      closeOnDocumentClick
      onClose={closeDtcModal}
    >
      <ModalMedium className="modal">
        <ModalHeader>Issue DTC Credential</ModalHeader>
        <ModalContentWrapper>
          {connection && connectionFound ? (
            <ModalContent>
              <form onSubmit={handleSubmit} ref={dtcForm}>
                <InputBox>
                  <ModalLabel htmlFor="expiry-date">
                    Expiry Date
                  </ModalLabel>
                  <InputFieldModal
                    type="date"
                    name="expiry-date"
                    id="expiry-date"
                    placeholder=""
                  />
                </InputBox>
                <InputBox>
                  <ModalLabel htmlFor="dtc">
                    DTC
                  </ModalLabel>
                  <InputFieldModal
                    name="dtc"
                    id="dtc"
                    placeholder=""
                  />
                </InputBox>
                <InputBox>
                  <ModalLabel htmlFor="family-name">
                    Family Name
                  </ModalLabel>
                  <InputFieldModal
                    name="family-name"
                    id="family-name"
                    placeholder=""
                  />
                </InputBox>
                <InputBox>
                  <ModalLabel htmlFor="issuing-state">
                    Issuing State
                  </ModalLabel>
                  <InputFieldModal
                    name="issuing-state"
                    id="issuing-state"
                    placeholder=""
                  />
                </InputBox>
                <InputBox>
                  <ModalLabel htmlFor="upk">
                    UPK
                  </ModalLabel>
                  <InputFieldModal
                    name="upk"
                    id="upk"
                    placeholder=""
                  />
                </InputBox>
                <InputBox>
                  <ModalLabel htmlFor="chip-photo">
                    Chip Photo
                  </ModalLabel>
                  <InputFieldModal
                    name="chip-photo"
                    id="chip-photo"
                    placeholder=""
                  />
                </InputBox>
                <InputBox>
                  <ModalLabel htmlFor="created-date">
                    Created Date
                  </ModalLabel>
                  <InputFieldModal
                    type="date"
                    name="created-date"
                    id="created-date"
                    placeholder=""
                  />
                </InputBox>
                <InputBox>
                  <ModalLabel htmlFor="date-of-birth">
                    Date of Birth
                  </ModalLabel>
                  <InputFieldModal
                    type="date"
                    name="date-of-birth"
                    id="date-of-birth"
                    placeholder=""
                  />
                </InputBox>
                <InputBox>
                  <ModalLabel htmlFor="issuing-authority">
                    Issuing Authority
                  </ModalLabel>
                  <InputFieldModal
                    name="issuing-authority"
                    id="issuing-authority"
                    placeholder=""
                  />
                </InputBox>
                <InputBox>
                  <ModalLabel htmlFor="gender">
                    Gender
                  </ModalLabel>
                  <InputFieldModal
                    name="gender"
                    id="gender"
                    placeholder=""
                  />
                </InputBox>
                <InputBox>
                  <ModalLabel htmlFor="document-number">
                    Document Number
                  </ModalLabel>
                  <InputFieldModal
                    name="document-number"
                    id="document-number"
                    placeholder=""
                  />
                </InputBox>
                <InputBox>
                  <ModalLabel htmlFor="issue-date">
                    Issue Date
                  </ModalLabel>
                  <InputFieldModal
                    type="date"
                    name="issue-date"
                    id="issue-date"
                    placeholder=""
                  />
                </InputBox>
                <InputBox>
                  <ModalLabel htmlFor="nationality">
                    Nationality
                  </ModalLabel>
                  <InputFieldModal
                    name="nationality"
                    id="nationality"
                    placeholder=""
                  />
                </InputBox>
                <InputBox>
                  <ModalLabel htmlFor="document_type">Document Type</ModalLabel>
                  <InputFieldModal
                    name="document-type"
                    id="document-type"
                    placeholder=""
                  />
                </InputBox>
                <InputBox>
                  <ModalLabel htmlFor="given-names">
                    Given Names
                  </ModalLabel>
                  <InputFieldModal
                    name="given-names"
                    id="given-names"
                    placeholder=""
                  />
                </InputBox>
                <Actions>
                  <CancelBtn type="button" onClick={handleClosingModal}>
                    Cancel
                  </CancelBtn>
                  <SubmitBtnModal type="submit">Send</SubmitBtnModal>
                </Actions>
              </form>
            </ModalContent>
          ) : (
            <ModalContent>
              {!invitationURL ? (
                <>
                  <p>Loading invitation...</p>
                  <LoadingHolder>
                    <Spinner />
                  </LoadingHolder>
                </>
              ) : invitationSelected.workflow_status !== 'active' ? (
                <>
                  <p>Processing invitation...</p>
                  <LoadingHolder>
                    <Spinner />
                  </LoadingHolder>
                </>
              ) : (
                <>
                  <p>
                    Simply scan the following QR code to begin the issuance
                    process:
                  </p>
                  <div className="qr">
                    <p>
                      <QR value={invitationURL} size={256} renderAs="svg" />
                    </p>
                  </div>
                </>
              )}
            </ModalContent>
          )}
        </ModalContentWrapper>
        <CloseBtn onClick={handleClosingModal}>&times;</CloseBtn>
      </ModalMedium>
    </StyledPopup>
  )
}

export default FormDtcIssuance
